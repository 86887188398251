import React, { CSSProperties, FC } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'

import { Text } from 'mf-ui-components'

import parser from '../../../../utils/htmlParser'
import { ListWrapper } from './List.styles'
import { BaseBlockProps } from '../../../../types/BaseBlockProps'

type Props = BaseBlockProps & {
  values: string
  isCheckBox?: boolean
  childBlock?: boolean
  color?: CSSProperties['color']
}

export const List: FC<Props> = ({
  id,
  values,
  isCheckBox,
  childBlock,
  marginBottom,
  hiddenAt,
  color,
}) => {
  const TheList = (
    <Text el="div" styleName="bodyRegular" variant="secondary">
      <ListWrapper
        id={id}
        isCheckBox={isCheckBox}
        marginBottom={marginBottom}
        hiddenAt={hiddenAt}
        color={color}
      >
        {parser(values)}
      </ListWrapper>
    </Text>
  )

  if (childBlock) {
    return TheList
  }

  return (
    <Container>
      <Row>
        <Col col={12} lg={8} lgOffset={2} noGutter={false}>
          {TheList}
        </Col>
      </Row>
    </Container>
  )
}
