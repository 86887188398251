import { CSSProperties } from 'react'
import styled, { css } from 'styled-components'

import { marginBreakpoints, hideBlockAt } from '../../../../utils/commonAttrs'
import { spacing } from '../../../../utils/ui/spacing'
import { textLinkStyle } from '../../../common/textLinkStyle'

type ListWrapperProps = {
  marginBottom?: string
  hiddenAt?: string
  isCheckBox?: boolean
  color?: CSSProperties['color']
}

export const ListWrapper = styled.ul<ListWrapperProps>`
  ${({ marginBottom }) => marginBreakpoints({ marginBottom }, 0)};
  ${({ hiddenAt }) => hideBlockAt({ hiddenAt })}
  padding: 0 20px;

  li {
    line-height: 28px;
    list-style: ${({ isCheckBox }) => (isCheckBox ? 'none' : 'initial')};
    text-align: left;
    margin-bottom: ${spacing('xs')};
    overflow-wrap: break-word;
    color: ${({ color }) => (color ? color.concat(' !important') : 'inherit')};

    ::marker {
      color: ${({ theme }) => theme.tokens.interface.neutral.regular};
    }

    & > a {
      ${textLinkStyle}
      ${({ color }) =>
        color
          ? `color: ${color.concat(' !important')};`
          : ''}
      word-break: break-all;
    }

    &::before {
      ${({ isCheckBox }) =>
        isCheckBox
          ? css`
              content: '✔';
              padding-right: 10px;
              color: ${({ theme }) =>
                theme.tokens.interface.feedback.positive.strong};
              box-sizing: content-box;
            `
          : ''}
    }
  }
`
